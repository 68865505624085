import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import { FormGroup } from 'reactstrap';
import { updateState, resetCalculator, updateLoanCalculation, getServerDatetime } from 'actions/actions';
import { formatCurrency } from 'utils/format';
import styled from 'styled-components';
import { scroller } from 'react-scroll';

import Results from './Results';
import CalculatorOverlay from './CalculatorOverlay';

import Form from 'components/Common/Form/Form';
import Label from 'components/Common/Form/Label';
import Input from 'components/Common/Form/Input';
import Header from 'components/Common/Layout/Header';
import Navigation from 'components/Common/Layout/Navigation';
import Alert from 'components/Common/Layout/Alert';
import Disclaimer from 'components/Common/Layout/Disclaimer';

const Info = styled.div`
  ${props => props.theme.main.fonts.application.micro};
  margin-bottom: 8px;
`;

const StyledFormGroup = styled(FormGroup)`
  margin-bottom: 32px;
`;

export default React.memo(() => {
  const dispatch = useDispatch();  
  const { cifId, applicationId, firstPaymentDate, loanType, loanApproval, loanPurpose, accessToken, isMobile } = useSelector(state => state.reducer);
  const loanCalculator = useSelector(state => state.reducer.loanCalculator);
  const [calculatorOpen, setCalculatorOpen] = useState(false);
  const [loanAmountChanged, setLoanAmountChanged] = useState(false);
  const [loanTermChanged, setLoanTermChanged] = useState(false);  
  const [resultsError, setResultsError] = useState(false);

  const handleChange = (event) => {    
    const { name, value } = event.target;
    dispatch(updateState({
      loanPurpose: value
    }));
  }

  const submit = (e) => {
    e.preventDefault();
    if(canContinue && !calculatorOpen) {
      navigate('/employment', { replace: true });
    }
  }

  useEffect(() => {    
    dispatch(getServerDatetime({
      cifId: cifId,
      applicationId: applicationId
    }, accessToken, isMobile));
    if(loanApproval && loanApproval.needsToRecalculate) {      
      const term = loanApproval.maxLoanTerm >= parseInt(loanCalculator.term) ? loanCalculator.term : loanApproval.maxLoanTerm;
      const termUnits = loanCalculator.termUnits;
      const paymentFrequency = loanCalculator.paymentFrequency;

      if(parseInt(loanCalculator.loanAmount) > loanApproval.maxAmount) {
        setLoanAmountChanged(true);
      }
      if(parseInt(loanCalculator.term) > loanApproval.maxLoanTerm) {
        setLoanTermChanged(true);
      }

      dispatch(resetCalculator());
      dispatch(updateLoanCalculation({
        cifId: cifId,
        applicationId: applicationId,
        calculation: {
          request: {
            loanAmount: loanApproval.maxAmount, 
            term: term, 
            termUnits: termUnits, 
            paymentFrequency: paymentFrequency, 
            loanType: loanType
          }
        }
      }, accessToken, isMobile, true));
    }
  }, []);

  const onCalculatorOverlayClose = () => {
    if(firstPaymentDate === null) {
      scroller.scrollTo('firstPaymentDate', {
        duration: 500,
        smooth: true,
        offset: -57
      });
    }
  }


  let alertMessage = `You are eligible for a ${loanApproval ? formatCurrency(loanApproval.maxAmount, 0) : '-'} loan. Please confirm your details and select your first payment date.`;  

  if(loanAmountChanged && loanTermChanged) {
    alertMessage = `The maximum loan amount you are eligible for is ${loanApproval ? formatCurrency(loanApproval.maxAmount, 0) : '-'}, with a maximum loan length of ${loanApproval ? loanApproval.maxLoanTerm : '-'} months. Please review and confirm your updated loan details before continuing.`;
  }
  else if(loanAmountChanged) {
    alertMessage = `The maximum loan amount you are eligible for is ${loanApproval ? formatCurrency(loanApproval.maxAmount, 0) : '-'}. Please review and confirm your updated loan details before continuing.`
  }
  else if(loanTermChanged) {
    alertMessage = `You are eligible for a loan of ${loanApproval ? formatCurrency(loanApproval.maxAmount, 0) : '-'}. The maximum loan length we can offer you is ${loanApproval ? loanApproval.maxLoanTerm : '-'} months. Please review and confirm your updated loan details before continuing.`;
  }

  const canContinue = (loanPurpose && firstPaymentDate && !resultsError)

  return (
    <Form>
      <CalculatorOverlay isOpen={calculatorOpen} toggle={()=> setCalculatorOpen(!calculatorOpen)} onClosed={onCalculatorOverlayClose} />
      <Header>Your loan eligibility</Header>
      <Alert type={loanAmountChanged || loanTermChanged ? 'error' : 'success'}>{alertMessage}</Alert> 
      <Results editClick={() => setCalculatorOpen(true)} loanAmountChanged={loanAmountChanged} loanTermChanged={loanTermChanged} onError={(error) => setResultsError(error)} />
      <StyledFormGroup>
        <Label name="loanPurpose">Why do you want this loan?</Label>
        <Info>This will not affect your eligibility.</Info>
        <Input type="select" name="loanPurpose" onChange={handleChange} value={loanPurpose}>
          <option value="" disabled>Please select one</option>
          <option value="04">Debt Consolidation</option>
          <option value="05">Vacation</option>
          <option value="06">Tuition/School Expenses</option>
          <option value="09">Work Expense/Tools/Suppliers</option>
          <option value="13">Emergency Funds</option>
          <option value="14">Medical/Dental Expenses</option>
          <option value="15">Everyday Expenses</option>
        </Input>   
      </StyledFormGroup>
      <Navigation buttonLabel="Continue" onClickForward={submit} disabled={!canContinue} />
      <Disclaimer>
        <p id="footnote-1"><sup>1</sup> The annual percentage rate (APR) is compounded monthly, not in advance. APR assumes no fees or charges apply. If additional fees and/or charges apply, the total Cost of Credit and APR would increase. This product, its features and advertised interest rates may change at any time and may be withdrawn by Vancity at any time for any reason without prior notice to the member.</p>
        <p id="footnote-2"><sup>2</sup> Dates are calculated based on Pacific Standard Time (PST).</p>
      </Disclaimer>
    </Form>);
});